<template>
  <div>
    <!--begin::customer-->
    <div>

      <h3 class="card-label">
        {{ $t('MENU.item_label_management') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row">
        <div class="col-md-12">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-4 mb-4">
                <label>{{ $t('size') }}</label>
                <select @change="setHeightWidth" id="size" v-model="size"
                        :class="validation && validation.size ? 'is-invalid' : ''" class="custom-select"
                        name="">
                  <option v-for="(row, index) in size_option" :key="'a'+index" :value="row.id">{{ row.label }}</option>
                </select>
              </div>
              <div class="col-lg-4 mb-4">
                <label>{{ $t('height') }}</label>
                <input v-model="data.height" :class="validation && validation.height ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.height"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.height[0] }}
                </span>
              </div>
              <div class="col-lg-4 mb-4">
                <label>{{ $t('width') }}</label>
                <input v-model="data.width" :class="validation && validation.width ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.width"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.width[0] }}
                </span>
              </div>
              <div class="col-lg-4 mb-4">
                <label>{{ $t('margin') }}</label>
                <input v-model="data.margin" :class="validation && validation.margin ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.margin"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.margin[0] }}
                </span>
              </div>
              <div class="col-lg-4 mb-4">
                <label>{{ $t('padding') }}</label>
                <input v-model="data.padding" :class="validation && validation.padding ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.padding"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.padding[0] }}
                </span>
              </div>
              <div class="col-lg-4 mb-4">
                <label>{{ $t('label_per_row') }}</label>
                <input v-model="data.label_per_row" :class="validation && validation.label_per_row ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.label_per_row"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.label_per_row[0] }}
                </span>
              </div>
              <div class="col-lg-4 mb-4">
                <label>{{ $t('unit_standard') }}</label>
                <select id="unit_standard" v-model="data.unit_standard"
                        :class="validation && validation.unit_standard ? 'is-invalid' : ''" class="custom-select"
                        name="">
                  <option v-for="(row, index) in unit_standard_list" :key="'a'+index" :value="row.slug">{{
                      row.title
                    }}
                  </option>
                </select>
              </div>

              <div class="col-lg-4 mb-4">
                <label>{{ $t('font_size') }}</label>
                <input v-model="data.font_size" :class="validation && validation.font_size ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.font_size"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.font_size[0] }}
                </span>
              </div>


            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-4">
                <h5>{{ $t('barcode_settings') }} ({{ $t('pixel') }})</h5>
              </div>
              <div class="col-lg-4 mb-4">

                <label>{{ $t('height') }}</label>
                <input v-model="data.barcode_height" :class="validation && validation.barcode_height ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.barcode_height"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.barcode_height[0] }}
                </span>
              </div>
              <div class="col-lg-4 mb-4">
                <label>{{ $t('width') }}</label>
                <input v-model="data.barcode_width" :class="validation && validation.barcode_width ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.barcode_width"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.barcode_width[0] }}
                </span>
              </div>
              <div class="col-lg-4 mb-4">
                <label>{{ $t('margin') }}</label>
                <input v-model="data.barcode_margin" :class="validation && validation.barcode_margin ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.barcode_margin"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.barcode_margin[0] }}
                </span>
              </div>

              <div class="col-lg-4 mb-4">
                <label>{{ $t('font_size') }}</label>
                <input v-model="data.barcode_font_size" :class="validation && validation.barcode_font_size ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.barcode_font_size"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.barcode_font_size[0] }}
                </span>
              </div>


            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-4">
                <h5>{{ $t('preview') }}</h5>
              </div>
              <div class="col-lg-12 mb-4">
                <div class="w-100" v-if="is_show" style="height: 100%;overflow: scroll;">
                  <div id="ticket" style="width: 100%;height: auto;float: left;direction: ltr;display: block;background-color: rgb(255, 255, 255);">
                    <div :style="{'width': '100%', height: i_height,'display': 'flex'}">

                    <div :style="style_card">
                      <div :style="{padding: i_padding, 'border': '1px solid #000000','border-radius': '7px','width': '100%','float': 'left', 'font-size': font_size, 'font-weight': '700'}">
                        <div style="text-align: center;margin-bottom: 5px;">{{ 'اسم الشركة' }}</div>
                        <div style="text-align: center">
                          <hr style="width: 50%;margin: 0 auto;border: 1px solid #000000;">
                        </div>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                          <div style="text-align: start;">
                            <p style="margin: 0;"><span>Exp: </span><span>20-20-2024 </span></p>
                            <p style="width: 100%;text-align: left;margin: 0;"><span>Price: </span> <span>220</span> <span>SAR</span></p>
                          </div>
                          <span>{{ 'تمر محشي جوز ولوز' }}</span>
                        </div>
                        <div style="text-align: center;margin-top: 5px;">
                          <barcode :value="1234567891234" :options="barcode_options" tag="svg"></barcode>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pr-0 pl-0">
        <div class="row">
          <div class="col-lg-6 mt-5">
            <button class="btn btn-primary mr-2" type="reset" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "form-items-configuration",
  data() {
    return {
      mainRoute: 'items/items_configuration',
      mainRouteDependency: 'base/dependency',
      data: {
        height: 1,
        width: 1,
        margin: 0,
        padding: 0,
        label_per_row: null,
        unit_standard: 'mm',
        font_size: 14,

        barcode_height: 1,
        barcode_width: 1,
        barcode_font_size: 14,
        barcode_margin: 0,
      },
      size_option: [
        {label: '2*4', id: 1, height: 2, width: 4},
        {label: '2.5*5', id: 2, height: 2.5, width: 5},
        {label: '4*6', id: 3, height: 4, width: 6},
        {label: '5*8', id: 4, height: 5, width: 8},
      ],
      size: null,
      unit_standard_list: [],
      validation: null,
      is_show: true,
    };
  },
  computed: {
    style_card: function () {
      let _height = this.data.height ? this.data.height : 1;
      let _width = this.data.width ? this.data.width : 0;
      let _margin = this.data.margin ? this.data.margin : 0;
      let _unit_standard = this.data.unit_standard ? this.data.unit_standard : 'mm';

      let _height_unit = _height + _unit_standard;
      let _width_unit = _width + _unit_standard;
      let _margin_unit = _margin + _unit_standard;

      this.hideAndShow();
      return {
        'width': _width_unit,
        'height': _height_unit,
        'margin': _margin_unit,
        'float': 'left',
        'direction': 'ltr',
        'display': 'flex',
        'background-color': '#FFFFFF'
      }
    },
    i_padding: function () {
      let _padding = this.data.padding ? this.data.padding : 0;
      let _unit_standard = this.data.unit_standard ? this.data.unit_standard : 'mm';

      return _padding + _unit_standard;
    },
    barcode_options: function () {
      this.hideAndShow();
      return {
        height: this.data.barcode_height ? this.data.barcode_height : 1,
        width: this.data.barcode_width ? this.data.barcode_width : 1,
        fontSize: this.data.barcode_font_size ? this.data.barcode_font_size : 1,
        marginTop: this.data.barcode_margin ? this.data.barcode_margin : 0
      };
    },
    font_size: function () {
      let _unit_standard = this.data.unit_standard ? this.data.unit_standard : 'mm';
      let _font_size = this.data.font_size ? this.data.font_size : 14;
      this.hideAndShow();
      return _font_size + _unit_standard;
    },
    i_height: function () {
      let _height = this.data.height ? this.data.height : 1;
      let _margin = this.data.margin ? this.data.margin : 0;
      let _padding = this.data.padding ? this.data.padding : 0;
      let _unit_standard = this.data.unit_standard ? this.data.unit_standard : 'mm';
      let i_height =  (parseFloat(_height) + parseFloat(_margin) + parseFloat(_padding)).toFixed(2);

      this.hideAndShow();
      return  (i_height).toString() + _unit_standard;
    },
  },
  methods: {
    hideAndShow(){
      this.is_show = false;
      setTimeout(()=>{
        this.is_show = true;
      }, 500)
    },
    save() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        Vue.prototype.$postStatus = true;
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
          });
    },
    setHeightWidth() {
      let size = this.size_option.find((row) => row.id === this.size);
      this.data.height = size.height;
      this.data.width = size.width;
    },
    async getData() {
      ApiService.get(`${this.mainRoute}`).then((response) => {
        let data = response.data.data;
        this.data = {
          height: data.height ? data.height : 1,
          width: data.width ? data.width : 1,
          margin: data.margin ? data.margin : 0,
          padding: data.padding ? data.padding : 0,
          label_per_row: data.label_per_row,
          unit_standard: data.unit_standard ? data.unit_standard : 'mm',
          font_size: data.font_size ? data.font_size : 14,

          barcode_height: data.barcode_height ? data.barcode_height : 1,
          barcode_width: data.barcode_width ? data.barcode_width : 1,
          barcode_font_size: data.barcode_font_size ? data.barcode_font_size : 14,
          barcode_margin: data.barcode_margin ? data.barcode_margin : 0,
        };
      }).then(() => {
        this.setSizeDepindOnHeightWidth();
      });
    },
    setSizeDepindOnHeightWidth() {
      let size = this.size_option.find((row) => row.height === this.data.height && row.width === this.data.width);
      this.size = size ? size.id : null;
    },
    getUnitStandardList() {
      ApiService.get(this.mainRouteDependency + "/unit_standard").then((response) => {
        this.unit_standard_list = response.data.data;
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.item_configuration')}, {title: this.$t("MENU.item_label_management")}]);
  },
  created() {
    this.getData();
    this.getUnitStandardList();
  }
};
</script>
